import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Pushing Through Friction by Dan Na`}</h1>
    <p>{`These are my notes and thoughts about `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=8bxZuzDKoI0"
      }}>{`the talk, "Pushing Through Friction"`}</a>{` by Dan Na.`}</p>
    <p>{`"Friction" is when you have to decide if the steps needed to solve a problem are worth it. You will encounter friction everywhere but there is quite a lot of friction in growing companies.`}</p>
    <p>{`In addition to company growth, friction is caused mostly by `}<a parentName="p" {...{
        "href": "http://danluu.com/wat/"
      }}>{`normalized deviance`}</a>{`. Someone outside the organization sees clearly that something isn't ideal whereas folks inside the organization don't realize or just live with it. Pay attention when new people join your organization and they suggest enhancements. I would add it's also important to take note when new hires are confused by something. It often indicates there's room to make things more clear.`}</p>
    <p>{`How can we prevent friction? Dan suggests a few short-term and long-term fixes.`}</p>
    <p>{`Short-term organizational fixes:`}</p>
    <ul>
      <li parentName="ul">{`Document single sources of truth and keep them updated`}</li>
      <li parentName="ul">{`Adopt processes to vet technology decisions (RFCs, architecture review, incident postmortems, etc)`}</li>
      <li parentName="ul">{`Solicit the WTF of new hires`}</li>
    </ul>
    <p>{`Long-term organizational fixes:`}</p>
    <ul>
      <li parentName="ul">{`Address hard truths, kindly`}</li>
      <li parentName="ul">{`Celebrate the `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=KClAPipnKqw"
        }}>{`glue work`}</a></li>
      <li parentName="ul">{`Make psychological safety paramount`}</li>
    </ul>
    <p>{`To facilitate change as an individual, Dan suggests to identify when something is wrong, come up with a plan to fix it and take ownership of the initiative. My initial thoughts about this lined up with some of the comments on the talk. The approach makes sense on paper but not everyone has this drive. Most individual contributors don't think it's worth it to put in the extra work for the good of the company.`}</p>
    <p>{`That being said, I think there's value in recognizing the folks who `}<em parentName="p">{`do`}</em>{` think it's worth it to put in the extra work. I love the idea of making glue work mandatory for promotion. This is the job.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      